angular.module('enervexSalesappApp').controller('AddLineitemModalCtrl', function($scope, $uibModalInstance, _, Product, Flash, Util, Application, VentMaterial, Fitting, Baffle, ProductType, ProductSubtype, Manufacturer, InsulationType, MetalType, isApplicationSetting, addProduct, LineItemUtil) {
	// $scope.upload = {}
	$scope.ctxModal = {
		deleted:'false',
		showDeleteOptions: true,
		productType: '',
		productPageSize: 700,
		duplicate: '',
		isAggregate:'',
		productNameFilter: ''
	}
	$scope.products = []
	$scope.isApplicationSetting = isApplicationSetting

	Fitting.query({active:true}).$promise.then(function(result){
		$scope.fittings = result
	})
	ProductType.query().$promise.then(function(productTypes){
		$scope.productTypes = _.filter(productTypes, function(o){
			return o.active
		})
		$scope.productTypes = _.sortBy($scope.productTypes, function(o){
			return o.name.toLowerCase()
		})
	})
	ProductSubtype.query().$promise.then(function(productSubtypes){
		$scope.productSubtypes = _.map(productSubtypes, function(o){
			o.nameActive = o.active ? o.name : o.name + " (inactive)"
			return o
		})
		$scope.productSubtypes = _.sortBy($scope.productSubtypes, function(o){
			return o.name.toLowerCase()
		})
	})
	Manufacturer.query().$promise.then(function(result){
		$scope.manufacturers = result
		var manufacturer = _.find(result, function(o){
			return o.isDefault
		})
		if ($scope.accountId) {
			$scope.ctxModal.manufacturer = manufacturer._id
		}
	})
	VentMaterial.query().$promise.then(function(result){
		$scope.ventMaterials = result
	})
	Baffle.query().$promise.then(function(result){
		$scope.baffles = result
	})
	MetalType.query().$promise.then(function(result){
		$scope.metalTypes = result
	})
	InsulationType.query().$promise.then(function(result){
		$scope.insulationTypes = result
	})

	$scope.ventDiameters = [2,3,4,5,6,7,8,9,10,12,14,16,18,20,22,24,26,28, 30, 32, 34, 36,38, 40 , 42, 44, 46];
	$scope.spaced = function(value) {
		return value.split(",").join(", ")
	}
	$scope.toUrl = function(snippet){
		if ($scope.accountId){
			return "/accounts/"+$scope.accountId + snippet;
		} else {
			return snippet;
		}
	}
	$scope.canAdd = function() {
		var found = _.find($scope.products, function(product){
			return product.quantity > 0
		})
		return found ? true : false
	}
	$scope.searchProducts = function(topLevel) {
		if (!topLevel) {
			return
		}
		if ($scope.configs.productPageSize){
			$scope.ctxModal.productPageSize = parseInt($scope.configs.productPageSize)
		}
		var params = {limit:$scope.ctxModal.productPageSize};
		if ($scope.ctxModal.productNameFilter &&  $scope.ctxModal.productNameFilter != "") {
			params.term = $scope.ctxModal.productNameFilter
		}
		if ($scope.ctxModal.type && $scope.type != ''){
			params.type = $scope.ctxModal.type;
		}
		if ($scope.ctxModal.deleted != ''){
			params.deleted = $scope.ctxModal.deleted;
		}
		if ($scope.ctxModal.isAggregate != ''){
			params.isAggregate = $scope.ctxModal.isAggregate;
		}
		if ($scope.ctxModal.duplicate != ''){
			params.duplicate = $scope.ctxModal.duplicate;
		}
		if ($scope.ctxModal.subtype && $scope.ctxModal.subtype != '') {
			params.subtype = $scope.ctxModal.subtype
		}
		if ($scope.ctxModal.ventMaterials && $scope.ctxModal.ventMaterials != '') {
			params.ventMaterials = $scope.ctxModal.ventMaterials
		}
		if ($scope.ctxModal.dim1 && $scope.ctxModal.dim1 != 0 && $scope.ctxModal.dim1 != "") {
			params.dim1 = $scope.ctxModal.dim1
		}
		if ($scope.ctxModal.couplerDim1 && $scope.ctxModal.couplerDim1 != 0 && $scope.ctxModal.couplerDim1 != "") {
			params['coupler.dim1'] = $scope.ctxModal.couplerDim1
		}
		if ($scope.ctxModal.fitting && $scope.ctxModal.fitting != "") {
			params.fitting = $scope.ctxModal.fitting
		}
		if ($scope.ctxModal.baffle && $scope.ctxModal.baffle != "") {
			params.baffle = $scope.ctxModal.baffle
		}
		if ($scope.ctxModal.outerMetalType && $scope.ctxModal.outerMetalType != '') {
			params.outerMetalType = $scope.ctxModal.outerMetalType
		}
		if ($scope.ctxModal.manufacturer && $scope.ctxModal.manufacturer != '') {
			params.manufacturer = $scope.ctxModal.manufacturer
		}
		if ($scope.ctxModal.innerMetalType && $scope.ctxModal.innerMetalType != '') {
			params.innerMetalType = $scope.ctxModal.innerMetalType
		}
		if ($scope.ctxModal.insulationType && $scope.ctxModal.insulationType != '') {
			params.insulationType = $scope.ctxModal.insulationType
		}
		Flash.clear()
		Flash.create("info", '<strong>Search Products...</strong>', 0, {}, true);
		Product.query(params).$promise.then(function(products){
			$scope.products = products
			if (products.length == $scope.ctxModal.productPageSize) {
				Flash.clear()

				var message = "Showing the max " + products.length + " products, please narrow your search";
				Flash.create('danger', '<strong>Warnging!</strong> ' + message, 0, {}, true);
			} else {
				Flash.clear()
				Flash.create("info", '<strong>Search Products</strong> found ' + products.length + ' items.', 0, {}, true);
			}
		});
	}
	$scope.newItemModal = createNewItemModal()

	function createNewItemModal(previousItem) {
		if (previousItem) {
			var source = {}
			var previousSystemSouce = previousItem.systemSources[0]
			var previousSouce = previousItem.source
			var systemSource = {
				systemId: previousSystemSouce.systemId,
				lineitemSource: previousSystemSouce.lineitemSource
			}
			_.each($scope.lineitemSources, function(o){
				source[o.code] = 0
				systemSource[o.code] = 0
			})
			return {
				itemType: previousItem.itemType,
				quantity: 0,
				uom: "EA",
				origin: previousItem.origin,
				source: source,
				customProduct: {
					name: "",
					price: 0,
					description: "",
					unit: "EA"
				},
				systemSources: [systemSource]
			}
		} else {
			var source = {}
			var systemSource = {
				systemId: 1,
			}
			_.each($scope.lineitemSources, function(o){
				source[o.code] = 0
				systemSource[o.code] = 0
			})
			return {
				itemType: "existing",
				quantity: 0,
				uom: "EA",
				origin: 'manual',
				source: source,
				customProduct: {
					name: "",
					price: 0,
					description: "",
					unit: "EA"
				},
				systemSources: [systemSource]
			}
		}
	}
	$scope.validateProductModal = function() {
		var newItem = $scope.newItemModal

		Flash.clear()
		var messages = []
		if (!newItem.systemSources[0].lineitemSource && !isApplicationSetting) {
			messages.push("Please select a source")
		}
		if (messages.length > 0 && !isApplicationSetting) {
			var message = "<strong>Error!</strong> Cannot add line item"
			_.each(messages, function(o, index){
				message = message + '<div>'+ o+ '</div>'
			})
			Flash.create('danger', message, 0, {}, true);
			return false
		}
		return true
	}
	$scope.addProductModal = function(){
		//validate item
		var original = _.clone($scope.newItemModal)
		if (!$scope.validateProductModal()) {
			return
		}
		var numberProducts = 0
		var quantityAdded = 0		
		var lineitemSource = original.systemSources[0].lineitemSource
		_.each($scope.products, function(product){
			if (product.quantity > 0) {
				var source = {}
				var lineitemSource = original.systemSources[0].lineitemSource
				var systemId = original.systemSources[0].systemId
				var systemSource = {
					systemId: systemId
				}
				_.each($scope.lineitemSources, function(o){
					source[o.code] = 0
					systemSource[o.code] = 0
				})
				quantityAdded = quantityAdded + product.quantity
				numberProducts = numberProducts + 1
				source[lineitemSource] = product.quantity
				systemSource[lineitemSource] = product.quantity
				var item = {
					itemType: "existing",
					quantity: product.quantity,
					uom: "EA",
					origin: "manual",
					source: source,
					product: product,
					customProduct: {
						name: "",
						price: 0,
						description: "",
						unit: "EA"
					},
					systemSources: [systemSource],
				}
				$scope.ctx.formChanged = true

				if (isApplicationSetting) {
					var formatted = LineItemUtil.formatItem(item)
					addProduct(formatted)
				} else {
					$scope.design.items.push(item);
				}
			}
		})
		$scope.newItemModal = createNewItemModal(original);
		$scope.newItemModal.source[lineitemSource] = 0
		$scope.newItemModal.systemSources[0][lineitemSource] = 0
		
		if (!isApplicationSetting) {
			$scope.calcTotal()
			$scope.itemAdded()
		}
		Flash.clear()
		Flash.create("info", '<strong>Added Products Success!</strong> Successfully added ' + quantityAdded + ' items across ' + numberProducts + " products.", 0, {}, true);
	}

	//systems begin
	$scope.done = function($event) {
		$event.preventDefault()
		var modalInstance = $uibModalInstance.close();
	}
});
